<template>


  <div class="c-app flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1 class="text-center">Login Agenzie</h1>
                  <p class="text-muted text-center">Accedi alla dashboard di Funer24</p>
                  <CInput
                      v-model="username"
                      placeholder="Username"
                      autocomplete="email"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user"/>
                    </template>
                  </CInput>
                  <CInput
                      v-model="password"
                      placeholder="Password"
                      type="password"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked"/>
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-center">
                      <CButton
                          v-on:click="login"
                          color="primary"
                          size="lg"
                          class="px-4">
                        Accedi adesso
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

const API_CREATE_AZIENDA = '/dashboard/api/v0/azienda/create'

export default {
  name: 'Login',
  data() {
    return {
      nome_azienda: null,
      email: null,
      comuni: null,
      address: null,
      via: null,
      partita_iva: null,
      username: '',
      password: '',
    }
  },
  methods: {
    login() {
      axios.post('/rest-auth/login/', {
        username: this.username.trim(),
        email: this.username.trim(),
        password: this.password.trim(),
      }).then((response) => {
        if (response.status === 200) {
          localStorage.setItem('token', response.data.key)
          window.axios.interceptors.request.use(function (config) {
            config.headers.common = {
              Authorization: 'Token ' + response.data.key,
              "Content-Type": "application/json",
              Accept: "application/json"
            }
            return config;
          })

          this.$store.dispatch('agenzia/get')
          this.$router.push('/dashboard')
        }
      }, (e) => {
        console.trace(e);
        console.log("login", e.response.data);
        console.log("login", e.response.status);
        console.log("login", e.response.headers);
      });
    },
    async create() {
      try {
        await axios
            .post(API_CREATE_AZIENDA, {
              nome_azienda: this.nome_azienda,
              email: this.email,
              address: this.address,
              via: this.address,
              partita_via: this.partita_iva
            })

      } catch (e) {
        console.trace(e)
      }
    }
  },
}
</script>
